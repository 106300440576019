global._slicedToArray = (function () { function sliceIterator(arr, i) { var _arr = []; var _n = true; var _d = false; var _e = undefined; try { for (var _i = arr[Symbol.iterator](), _s; !(_n = (_s = _i.next()).done); _n = true) { _arr.push(_s.value); if (i && _arr.length === i) break; } } catch (err) { _d = true; _e = err; } finally { try { if (!_n && _i["return"]) _i["return"](); } finally { if (_d) throw _e; } } return _arr; } return function (arr, i) { if (Array.isArray(arr)) { return arr; } else if (Symbol.iterator in Object(arr)) { return sliceIterator(arr, i); } else { throw new TypeError("Invalid attempt to destructure non-iterable instance"); } }; })();

function createVideoHtml(src, element) {
    console.log(element.width());
    console.log(element.height());
    innerHtml =
        '<iframe width="' +
        element.width() +
        '" height="' +
        element.height() +
        '" src="' +
        src +
        '?autoplay=1" frameborder="0" allow="accelerometer; autoplay; encrypted-media; gyroscope; picture-in-picture" allowfullscreen></iframe>';

    element.html(innerHtml);
    $('#loading').addClass('d-none');
}

$('.display-youtube-video').on("click", function() {
    $('#loading').removeClass('d-none');
    var videoSrc = $(this).attr('data-src');

    if (typeof videoSrc !== typeof undefined && videoSrc !== false) {
        createVideoHtml($(this).attr('data-src'), $(this));
    }
});
