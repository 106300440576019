global.GoogleMapsLoader.KEY = 'AIzaSyCV7cV2l7QI2mQognpRc3qMjFYEvC7lJQQ';

global.GoogleMapsLoader.VERSION = "quarterly";
global.GoogleMapsLoader.load(function(google) {
    $('[data-google-maps]').each(function(index) {
        applyGoogleMaps($(this));
    });
});

global.applyGoogleMaps = function (element) {
    dataLocalBusiness = $(element).attr('data-local-business');
    if (dataLocalBusiness != '') {
        var data = JSON.parse($(element).attr('data-local-business'));
        if (data.place !== undefined) {
            var address = data.place.address;

            var myLatlng = new google.maps.LatLng(address.latitude, address.longitude);

            var mapOptions = {
                center: myLatlng,
                zoom: parseInt($(element).attr('data-zoom')),
                mapTypeControl: false
            };

            var map = new google.maps.Map($(element)[0], mapOptions);

            textAddress = address.streetAddress + " " +  address.postalCode + " " + address.addressLocality + " " + address.addressCountry;
            var marker = new google.maps.Marker({
                position: myLatlng,
                title: textAddress
            });
            var contentString = '<div id="content">'+
                '<div id="siteNotice">'+
                '</div>'+
                '<h3 id="firstHeading" class="firstHeading">' + data.name + '</h3>'+
                '<div id="bodyContent">'+
                '<p>' +
                textAddress +
                '</p>' +
                '<p>' +
                '<a class="text-primary" target="_blank" href="https://maps.google.com/?q='+ textAddress + '">Google Maps</a>' +
                '</p>' +
                '</div>'+
                '</div>';
            var infowindow = new google.maps.InfoWindow({
                content: contentString
            });
            marker.addListener('click', function() {
                infowindow.open(map, marker);
            });

            marker.setMap(map);
        }
    }
};

local_business_component = {
    methods: {
        viewMap: function(local, event) {
            target = event.target;
            if ($(target).is('i')) {
                target = $(event.target).closest('a');
            }
            if ($(target).attr('data-text-show').substr(0, 8) == $(target).html().trim().substr(0, 8)) {
                element = $(target).closest('tbody').find('.map').hide();
                $(target).closest('tbody').find('tr').removeClass('selected');
                $(target).closest('tbody').find('a.btn-map').html($(target).attr('data-text-show'));

                $(target).html($(target).attr('data-text-hide'));
                $(target).closest('tr').addClass('selected');

                //Show map
                element = $(target).closest('tr').next('tr').find('.map')[0];
                element.setAttribute("data-local-business", JSON.stringify(local));
                $(element).css('height', '400px');
                applyGoogleMaps(element);
                $(element).show();

                //Show container
                elementContainer = $('.map-container');
                $(elementContainer).show();

            } else {
                $(target).html($(target).attr('data-text-show'));
                $(target).closest('tr').removeClass('selected');

                //Hide map
                element = $(target).closest('tr').next('tr').find('.map')[0];
                $(element).hide();

                //Hide container
                elementContainer = $('.map-container');
                $(elementContainer).hide();
            }
        },
        refresh: function(condition, value, local) {
            var found = false;
            var postalCode = '';
            var dealersInCountry = '', dealersInProvince = '';
            var n = 0;

            var selectedCountry = this.country;

            this.localbusiness.forEach(function(local) {

                console.log(selectedCountry, local.place.address.addressCountryCode);

                var localPostalCodes = null;
                if(local.postalCodes != null){
                    localPostalCodes = local.postalCodes.split('|');
                }

                if(selectedCountry == local.place.address.addressCountryCode) {
                    if (

                        (condition == 'province' && local.place.address.postalCodeFilth == undefined && (local.place.address.postalCode != null && local.place.address.postalCode.substring(0, 2) == value)) ||
                        (condition == 'province' && local.place.address.postalCodeFilth == undefined && (localPostalCodes != null && localPostalCodes.includes(value))) ||
                        (condition == 'province' && local.place.address.postalCodeFilth !== undefined && (local.place.address.postalCodeFilth.substring(0, 2) == value)) ||
                        (condition == 'country' && (local.place.address.addressCountryCode == value))
                    ) {

                        if (local.place.address.postalCode != null)
                            postalCode = local.place.address.postalCode.substring(0, 2);
                        else if (localPostalCodes != null && localPostalCodes.includes(value)) {
                            postalCode = value;
                        } else
                            postalCode = '';

                        found = true;
                        dealersInProvince = local.place.address.addressRegion;
                        dealersInCountry = local.place.address.addressCountry;
                        n++;
                    }
                }
            });

            if (condition == "country") {
                this.postalcode = '';
                this.dealersin = dealersInCountry;

                /* if (n > 1) {
                    this.postalcode = '';
                    this.dealersin = dealersInCountry;
                } else if (n == 1) {
                    this.postalcode = postalCode;
                    this.dealersin = dealersInProvince;
                } */
            } else if (condition == "province" && postalCode != '') {
                this.postalcode = postalCode;
                this.dealersin = dealersInProvince;
            }

            this.nodata = !found;
        },
        changeProvince: function() {
            this.refresh('province', this.postalcode);
            this.hideMap();
        },
        changeCountry: function() {
            var country = this.country;
            this.refresh('country', country);
            $('.cms-block.map').addClass('d-none');
            $('.cms-block.map.map-' + country.toLowerCase()).removeClass('d-none');
            this.hideMap();
        },
        hideMap: function() {
            this.$el.querySelectorAll('div.map').forEach(el => {
                el.style.display = "none";
                el.innerHTML = "";
            });

            this.showmap = false;
            this.nodata = false;
        }
    },
    data: {
        country: 'ES',
        province: 'ESPAÑA',
        postalcode: '',
        showselector: true,
        showmap: false,
        nodata: false,
        local: {'place': {'address': {'streetAddress': '', 'addressRegion': ''}}}
    }

};
