function zoomerViewEvent(gallery){
    jQuery(gallery).find(".slider-view-zoom").css("display", "none");

    jQuery(gallery).find(".slider-view").hover(
        function(){
            jQuery(gallery).find(".slider-view-zoom").css("display", "block");
        },
        function(){
            jQuery(gallery).find(".slider-view-zoom").css("display", "none");
        }
    );

    jQuery(gallery).find(".slider-view-zoom").hover(function(){
      jQuery(gallery).find(".slider-view-zoom").css("display", "block");
    });
}

jQuery(document).ready(function() {

    // Gallery with selectable box image
    jQuery('.cms-block-gallery .gallery-slick-v1').each(function(index) {
        id = jQuery(this).closest('.cms-block-gallery').attr('id');
        zoomerViewEvent(jQuery(this));
        jQuery(this).find('.slider-for').slick({
            slidesToShow: 1,
            slidesToScroll: 1,
            arrows: false,
            fade: true,
            asNavFor: '#' + id + ' .gallery-slick-v1 .slider-nav'
        });
        jQuery(this).find('.slider-nav').slick({
            slidesToShow: 3,
            slidesToScroll: 3,
            asNavFor: '#' + id + ' .gallery-slick-v1 .slider-for',
            dots: true,
            centerMode: false,
            focusOnSelect: true
        });

        lightbox.option({
            'showImageNumberLabel':false
        });
    });

    // Simple gallery
    jQuery('.cms-block-gallery .gallery-slick-v0').slick();
});
