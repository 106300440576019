$.fn.vueFrontend = function() {
    function init() {
        Vue.filter('trans', function(t, that) {
            if (that == undefined) {
                return eval("Vue.prototype.translations_translations." + t);
            } else {
                if (that.translations !==undefined && that.translations[t.split('.')[0]] !== undefined) {
                    return eval("that.translations." + t);
                } else if (that[t.split('.')[0]] !== undefined) {
                    return eval("that." + t);
                }

                return '';
            }
        });
        $('[id^="v-block-"]').each(function () {
            id = $(this).attr('id');
            dataId = $(this).attr('data-id');
            vuejs = {};
            var dataVuejs = $(this).data('arrayVuejs');
            if (dataVuejs !== undefined && dataVuejs.length > 0) {
                vuejs = eval(dataVuejs)[0];
                delete vuejs.data;
            }

            loadData($(this).data(), dataId);

            if ($(this).attr('data-block-type') !== undefined) {
                blockType = $(this).attr('data-block-type').split('.').pop();
                try {
                    if (vuejs.methods === undefined) {
                        vuejs.methods = {};
                    }
                    if (vuejs.filters === undefined) {
                        vuejs.filters = {};
                    }
                    if (vuejs.computed === undefined) {
                        vuejs.computed = {};
                    }
                    if (eval(blockType + "_component.created") !== undefined) {
                        vuejs.created = eval(blockType + "_component.created");
                    }
                    if (eval(blockType + "_component.mounted") !== undefined) {
                        vuejs.mounted = eval(blockType + "_component.mounted");
                    }
                    Object.assign(vuejs.methods, eval(blockType + "_component.methods"));
                    Object.assign(vuejs.filters, eval(blockType + "_component.filters"));
                    Object.assign(vuejs.computed, eval(blockType + "_component.computed"));
                } catch(err) {}
            }
            Vue.component(
                id,
                Object.assign({
                    delimiters: ["{[", "]}"],
                    template: '#' + id,
                    data: function (componentData) {
                        regexs = [
                            /{\[([ a-zA-Z._0-9]*)\]}/gm,
                            /{#([ a-zA-Z._0-9]*)#}/gm,
                            /{!([ a-zA-Z._0-9]*)!}/gm,
                        ];
                        vBlock = $("#" + this.$options._componentTag);
                        vueVars = extract_interior_expression($(vBlock).html(), regexs);

                        twigVueVars = loadData($(vBlock).data(), $(vBlock).attr('data-id'));

                        result = {};
                        for (var key in vueVars) {
                            tmp = "result[vueVars[key]] = this." + vueVars[key];
                            eval(tmp);
                        }
                        for (var key in twigVueVars) {
                            tmp = "result[twigVueVars[key]] = this." + twigVueVars[key];
                            eval(tmp);
                        }

                        componentData = [];
                        if ($("#" + this.$options._componentTag).attr('data-block-type') !== undefined) {
                            blockType = $("#" + this.$options._componentTag).attr('data-block-type').split('.').pop();
                            try {
                                componentData = eval(blockType + "_component.data");
                            } catch(err) {}
                        }

                        for (var i in componentData) {
                            result[i] = componentData[i];
                        }

                        return result;
                    },
                }, vuejs)
            );
        });

        if ($('[id^="v-block-"]').length > 0) {
            window.vm = new Vue({
                el: '#app',
                delimiters: ["{[", "]}"],
                axios
            });
        }
    }

    function loadData(data, id) {
        result = [];
        for (var key in data) {
            if (key.substr(0, 5) == "array") {
                dataKey = key.substr(5).toLowerCase();
                tmp = "Vue.prototype." + dataKey + " = data[key];";
                result.push(dataKey);
                eval(tmp);
                if (dataKey == "translations" && id !== undefined) {
                    dataKey2 = dataKey + "_" + id.replace(new RegExp('-', 'g'), '_');
                    tmp = "Vue.prototype." + dataKey2 + " = data[key];";
                    result.push(dataKey2);
                    eval(tmp);
                }
                if (dataKey == "vuejs") {
                    dataVuejs = eval(data[key])[0];
                    if (dataVuejs !== undefined && dataVuejs.data !== undefined) {
                        datas = dataVuejs.data;
                        if (datas !== undefined) {
                            for (var i in datas) {
                                tmp = "Vue.prototype." + i + " = datas[i];";
                                eval(tmp);
                            }
                        }
                    }
                }
            } else {
                tmp = "Vue.prototype." + key + " = data[key];";
                eval(tmp);
            }
        };

        return result;
    }

    function extract_interior_expression(str, regexs) {
        let m;
        result = [];
        regexs.forEach(function(regex) {
            while ((m = regex.exec(str)) !== null) {
                if (m.index === regex.lastIndex) {
                    regex.lastIndex++;
                }
                m.forEach((match, groupIndex) => {
                    if (groupIndex == 1) {
                        result.push(match.trim());
                    }
                });
            }

        });

        return result;
    }

    init();
};
