$('.cms-block-category a[data-no-linkable]').on("click", function() {
    $(this).closest('.cms-block-category').find('a[data-no-linkable]').removeClass('selected');
    $(this).addClass('selected');
    var ids = JSON.parse($(this).attr('data-ids'));
    var parentIds = JSON.parse($(this).attr('data-parent-ids'));
    parentId = $(this).attr('data-parent-id');
    $('.cms-block-category a[data-linkable]').removeClass('selected');
    $('.cms-block-category a[data-linkable]').each(function(index) {
        if (parentIds.indexOf(parseInt($(this).attr('data-id'))) !== -1) {
            $(this).addClass('selected');
            href = $(this).attr('data-href');
            childrenIds = JSON.parse($(this).attr('data-children-ids'));
            categoryId = 0;
            for (var i in ids) {
                if (childrenIds.indexOf(ids[i]) !== -1) {
                    categoryId = ids[i];
                    break;
                }
            }

            $(this).attr('href', href.replace('CATEGORY_ID', categoryId));
        }
    });

    href = $('.cms-block-category a.all').attr('data-href');
    $('.cms-block-category a.all').attr('href', href.replace(/CATEGORY_NAME/g, $(this).attr('data-name')))
        .addClass('selected');
});
