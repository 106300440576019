// TODO: Refactoring all
$.fn.balloon = function () {
    var plano = {
        url: '',
        id_plano: 0,
        ancho: 0,
        alto: 0,
        k_prop: 1,
        k_escala: 1,
        dx_origen: 0,
        dy_origen: 0,
        id_DAM: 0
    }
    var acetato = {};
    var capa = {
        indice: 0,
        nombre: '',
        tipo: '',
        label: '',
        x1: 0,
        y1: 0,
        x2: 0,
        y2: 0,
        x1_plano: 0,
        y1_plano: 0,
        x2_plano: 0,
        y2_plano: 0
    };
    var mCapas = [];
    var capaSel = 1;
    var nCapas = 0;
    var puntero = { x: 0, y: 0 };
    var rGlobo = 10;
    var rVert = 4;
    var resizeId = 0;
    var json_CAD_desglosado = '';
    //
    var modulo_activo = '';
    var modal = false;

    // CAD
    $(function () {
        $(document).mousemove(function (event) {
            puntero.x = event.pageX;
            puntero.y = event.pageY;
        });
    });

    function mostrarCapas() {
        s = '';
        for (var i in mCapas) {
            s += '<tr><td id="capa' + mCapas[i].indice.toString() + '" class="capa_CAD" onClick="seleccionarCapa(' + mCapas[i].indice + ')">' + mCapas[i].nombre + '</td></tr>';
        }
        s += '<tr><td class="capa_vac_CAD"></td></tr>';
        //
        $('#t_capas_CAD').html(s);
    }

    function mostrarGraficos() {
        var s = '';
        var sId = '';
        var n = '';
        for (var i in mCapas) {
            switch (mCapas[i].tipo) {
                case 'G':
                    sId = 'G' + mCapas[i].indice.toString();
                    s += '<div id="' + sId + '" class="ui-widget-content globo" data-globo-id="' + mCapas[i].label.toString() + '" onclick="$.fn.balloon().selectPiece(' + mCapas[i].label.toString() + ')"><div class="label">' + mCapas[i].label.toString() + '</div></div>';
                    break;
                case 'L':
                    sId = 'L' + mCapas[i].indice.toString();
                    s += '<div id="' + sId + '-1" class="ui-widget-content vertice"></div>';
                    s += '<div id="' + sId + '-2" class="ui-widget-content vertice"></div>';
                    break;
                default:
                    break;
            }
        }

        $('#objs_CAD').html(s);

        for (var i in mCapas) {
            n = '#' + mCapas[i].tipo + mCapas[i].indice.toString();

            switch (mCapas[i].tipo) {
                case 'G':
                    $(n).offset({ left: mCapas[i].x1, top: mCapas[i].y1 });
                    $(n).val(i); // i de mCapas (no índice)
                    break;
                case 'L':
                    if (mCapas[i] != undefined) {
                        $(n + '-1').offset({ left: mCapas[i].x1, top: mCapas[i].y1 });
                        $(n + '-1').val(i); // i de mCapas (no índice)
                        //
                        $(n + '-2').offset({ left: mCapas[i].x2, top: mCapas[i].y2 });
                        $(n + '-2').val(i); // i de mCapas (no índice)
                    }
                    break;
                default:
                    break;
            }
        }
        //
        seleccionarCapa(capaSel);
        redibujarTodo();
    }

    function redibujarTodo() {
        limpiarCanvasCAD();

        for (var i in mCapas) {
            if (mCapas[i].tipo == 'L') {
                if (mCapas[i] != undefined) {
                    dibujarLineaCAD(mCapas[i].x1, mCapas[i].y1, mCapas[i].x2, mCapas[i].y2);
                }
            }
        }
    }

    function limpiarCanvasCAD() {
        if (document.getElementById('acetato')) {
            var ctx = document.getElementById('acetato').getContext('2d');
            ctx.setTransform(1, 0, 0, 1, 0, 0);
            ctx.beginPath();
            ctx.clearRect(0, 0, acetato.width, acetato.height);
        }
    }

    function dibujarLineaCAD(x1, y1, x2, y2) {
        var ctx = document.getElementById('acetato').getContext('2d');
        var ox = $('#acetato').offset().left;
        var oy = $('#acetato').offset().top;
        ctx.setTransform(1, 0, 0, 1, 0, 0);
        ctx.translate(-ox, -oy); //mueve origen
        ctx.beginPath();
        ctx.setLineDash([0]);
        ctx.strokeStyle = '#000';
        ctx.lineWidth = 0.3;
        ctx.lineCap = 'round';
        ctx.moveTo(x1 + rVert, y1 + rVert);
        ctx.lineTo(x2 + rVert, y2 + rVert);
        ctx.stroke();
    }

    function anadir_capa(tipo, ind_capa, sNombre, sEtiqueta, dx1, dy1, dx2, dy2, dx1_plano, dy1_plano, dx2_plano, dy2_plano) {
        switch (tipo) {
            case 'G':
                mCapas.push({
                    indice: ind_capa,
                    nombre: sNombre,
                    tipo: 'G',
                    label: sEtiqueta,
                    x1: dx1,
                    y1: dy1,
                    x2: 0,
                    y2: 0,
                    x1_plano: dx1_plano,
                    y1_plano: dy1_plano,
                    x2_plano: 0,
                    y2_plano: 0
                });
                break;
            case 'L':
                mCapas.push({
                    indice: ind_capa,
                    nombre: sNombre,
                    tipo: 'L',
                    label: sEtiqueta,
                    x1: dx1,
                    y1: dy1,
                    x2: dx2,
                    y2: dy2,
                    x1_plano: dx1_plano,
                    y1_plano: dy1_plano,
                    x2_plano: dx2_plano,
                    y2_plano: dy2_plano
                });
                break;
            default:
                break;
        }
    }

    function crear_capa(tipo, indice, sNombre, sEtiqueta, dx1, dy1, dx2, dy2) {
        nCapas++;
        //
        var dx1_plano = dx1;
        var dy1_plano = dy1;
        var dx2_plano = dx2;
        var dy2_plano = dy2;
        //
        switch (tipo) {
            case 'G':
                // Ajustes de XGrafs fuera de límites (globos)
                if (dx1 < rGlobo) {
                    dx1 = rGlobo + 2;
                }
                if (dy1 < rGlobo) {
                    dy1 = rGlobo + 2;
                }
                if (dx1 > (plano.ancho - rGlobo)) {
                    dx1 = plano.ancho - rGlobo + 2;
                }
                if (dy1 > (plano.alto - rGlobo)) {
                    dy1 = plano.alto - rGlobo + 2;
                }
                // Paso de coord XGraf a la posición y escala de #acetato, con
                // posicionamiento de globo a su centro
                dx1 = acetato.left + plano.dx_origen + (dx1 - rGlobo) * plano.k_escala;
                dy1 = acetato.top + plano.dy_origen + (dy1 - rGlobo) * plano.k_escala;
                break;
            case 'L':
                // Ajustes de XGrafs fuera de límites (vértices)
                if (dx1 < rVert) {
                    dx1 = rVert + 2;
                }
                if (dy1 < rVert) {
                    dy1 = rVert + 2;
                }
                if (dx1 > (plano.ancho - rVert)) {
                    dx1 = plano.ancho - rVert + 2;
                }
                if (dy1 > (plano.alto - rVert)) {
                    dy1 = plano.alto - rVert + 2;
                }
                //
                if (dx2 < rVert) {
                    dx2 = rVert + 2;
                }
                if (dy2 < rVert) {
                    dy2 = rVert + 2;
                }
                if (dx2 > (plano.ancho - rVert)) {
                    dx2 = plano.ancho - rVert + 2;
                }
                if (dy2 > (plano.alto - rVert)) {
                    dy2 = plano.alto - rVert + 2;
                }
                // Paso de coord XGraf a la posición y escala de #acetato, con
                // posicionamiento de vértice a su centro
                dx1 = acetato.left + plano.dx_origen + (dx1 - rVert) * plano.k_escala;
                dy1 = acetato.top + plano.dy_origen + (dy1 - rVert) * plano.k_escala;
                dx2 = acetato.left + plano.dx_origen + (dx2 - rVert) * plano.k_escala;
                dy2 = acetato.top + plano.dy_origen + (dy2 - rVert) * plano.k_escala;
                break;
            default:
                break;
        }

        anadir_capa(tipo, indice, sNombre, sEtiqueta, dx1, dy1, dx2, dy2, dx1_plano, dy1_plano, dx2_plano, dy2_plano);

        capaSel = indice; //nCapas;
    }

    function crear_objetos_CAD(json_CAD_desglosado) {
        dimensionar_acetato();
        asignar_objetos_CAD(JSON.parse(json_CAD_desglosado));
        capaSel = 1;
        mostrarCapasYGraficos();
        // si plano vacío: borra mensaje de carga
        if (nCapas == 0) {
            $('#sel_aplicaciones').html('');
        }
        $('.mark-number.selected a').click();
    }

    function asignar_objetos_CAD(m) {
        var iObj, indice, tipo, iCoord, sNombreCapa, sEtiquetaGlobo, dx1, dy1, dx2, dy2;

            iObj = 0;
            while (isset(m[iObj])) {
                indice = m[iObj][0];

                if (indice < 101) {
                    tipo = 'G';
                } else {
                    tipo = 'L';
                }

                switch (tipo) {
                    case 'G':
                        sNombreCapa = 'GLOBO  ' + m[iObj][0].toString(); // se pasa a ser
                        // reenumerado cada
                        // vez, con el valor
                        // del índice (al
                        // Guardar, se
                        // corrigen las
                        // numeraciones XGraf
                        // de fábrica)
                        sEtiquetaGlobo = m[iObj][1].toString();
                        break;
                    case 'L':
                        sNombreCapa = 'LÍNEA  ' + m[iObj][1];
                        sEtiquetaGlobo = m[iObj][1];
                        break;
                    default:
                        break;
                }

                iCoord = 0;
                while (isset(m[iObj][2][iCoord])) {
                    // console.log ('Coord.' + iCoord + ':' + m[iObj][2][iCoord] + ' ');
                    iCoord++;
                }

                if (tipo == 'G') {
                    dx1 = m[iObj][2][0];
                    dy1 = m[iObj][2][1];
                    crear_capa('G', indice, sNombreCapa, sEtiquetaGlobo, dx1, dy1, 0, 0);
                }
                else {
                    dx1 = m[iObj][2][0];
                    dy1 = m[iObj][2][1];
                    dx2 = m[iObj][2][2];
                    dy2 = m[iObj][2][3];
                    crear_capa('L', indice, sNombreCapa, sEtiquetaGlobo, dx1, dy1, dx2, dy2);
                }
                iObj++;
            }
    }

    function seleccionarCapa(iSel) {
        var s = '';
        var sIdGlobo = '';
        var sIdV1 = '';
        var sIdV2 = '';
        var z = 0;
        //
        capaSel = iSel;
        //
        for (var i in mCapas) {
            s = '#capa' + mCapas[i].indice.toString();
            switch (mCapas[i].tipo) {
                case 'G':
                    sIdGlobo = '#G' + mCapas[i].indice.toString();
                    if (mCapas[i].indice == iSel) {
                        $(s).css('color', '#fff');
                        $(s).css('background-color', '#005');
                        //$(sIdGlobo).css('background-color', '#F90');
                        $(sIdGlobo).css('border-color', '#f60');
                        $(sIdGlobo).css('z-index', 1001);
                    }
                    else {
                        $(s).css('color', '#000');
                        $(s).css('background-color', '#fafafa');
                        //$(sIdGlobo).css('background-color', '#ffddbe');
                        $(sIdGlobo).css('border-color', '#ffddaa');
                        z = parseFloat(i) + 50;
                        $(sIdGlobo).css('z-index', z);
                    }
                    break;
                case 'L':
                    sIdV1 = '#L' + mCapas[i].indice.toString() + '-1';
                    sIdV2 = '#L' + mCapas[i].indice.toString() + '-2';
                    if (mCapas[i].indice == iSel) {
                        $(s).css('color', '#fff');
                        $(s).css('background-color', '#005');
                        $(sIdV1).css('border-color', '#f60');
                        $(sIdV1).css('z-index', '1001');
                        $(sIdV2).css('border-color', '#f60');
                        $(sIdV2).css('z-index', '1002');
                    }
                    else {
                        $(s).css('color', '#000');
                        $(s).css('background-color', '#fafafa');
                        $(sIdV1).css('border-color', '#ddd');
                        $(sIdV1).css('z-index', i);
                        $(sIdV2).css('border-color', '#ddd');
                        $(sIdV2).css('z-index', i);
                    }
                    break;
                default:
                    break;
            }
        }
    }

    function mostrarCapasYGraficos() {
        mostrarCapas();
        mostrarGraficos();
    }

    function configurar_plano() {
        if ((plano.alto == 0) || (acetato.width == 0)) {
            return;
        }

        var ancho_plano_escalado = 0;
        var alto_plano_escalado = 0;
        var k_prop_acetato = acetato.width / acetato.height;

        plano.k_prop = plano.ancho / plano.alto;

        switch (plano.k_prop >= k_prop_acetato) {
            case true:
                plano.k_escala = acetato.width / plano.ancho;
                alto_plano_escalado = plano.k_escala * plano.alto;
                plano.dx_origen = 0;
                plano.dy_origen = (acetato.height - alto_plano_escalado) / 2;
                break;
            default:
                plano.k_escala = acetato.height / plano.alto;
                ancho_plano_escalado = plano.k_escala * plano.ancho;
                plano.dx_origen = (acetato.width - ancho_plano_escalado) / 2;
                plano.dy_origen = 0;
                break;
        }
    }

    function dimensionar_acetato() {
        acetato = document.getElementById('acetato');

        maxWidth = $('.balloon-view').closest('.container').width();
        maxHeight = $('.balloon-view').closest('.container').width();
        $('#d_fondo').css('max-height', maxWidth+'px');
        $('#d_fondo').css('max-width', maxHeight+'px');

        acetato.width = $('#d_fondo').width();
        acetato.height = $('#d_fondo').height();
        acetato.left = $('#d_fondo').offset().left;
        acetato.top = $('#d_fondo').offset().top;

        configurar_plano();

        $('#acetato').show(0);

        if (isset(mCapas[0])) {
            nCapas = 0;
            mCapas = [];
            //antes: asignar_objetos_CAD (JSON.parse($('#json_cad_NS').html()));
            // asignar_objetos_CAD(JSON.parse(json_CAD_desglosado));
        }

        mostrarCapasYGraficos();
    }

    function iniciar_CAD() {
        modal = true;
        //
        mCapas = [];
        capaSel = 1;
        nCapas = 0;

        /* var url_string = window.location.href;
        var url = new URL(url_string); */
        // Get Coordinates from Parameters
        json_CAD_desglosado = $('#t_app').attr('data-coordinates');
    }

    // UTILS

    function html_capas() {
        return ('<table id="t_capas_CAD"></table>');
    }

    function htmls() {
        /* var url_string = window.location.href;
        var url = new URL(url_string); */
        var urlImage = $('#t_app').attr('data-image');

        $('#d_capas_CAD').html(html_capas());
        //
        abrir_plano(urlImage, 0);    // Se carga el plano y se construye la interfaz CAD
    }

    function isset(v) {
        return (typeof v != 'undefined');
    }

    function mostrar_CAD() {
        $('#d_ventana').scrollTop(0);
        $('#d_galeria').hide(0);

        iniciar_CAD();

        $('#d_fondo').html('<canvas id="acetato"></canvas><div id="objs_CAD"></div>');
        $('#d_ventana').css('background-color', '#fff');
        $('#d_fondo').css('background-color', '#fff');
        $('#d_fondo').show(0);
    }

    function mostrar_visor() {
        $('#d_ventana').scrollTop(0);
        $('#d_ventana').html('<div id="d_fondo"><canvas id="acetato"></canvas><div id="objs_CAD"></div></div>');
        $('#d_ventana').css('background-color', '#fff');
        $('#d_fondo').css('background-color', '#fff');
        $('#d_fondo').show(0);
        //
        $('#navegador').show();
    }

    function mostrarVentana() {
        switch (modulo_activo) {
            case 'VIS':
                mostrar_visor();
                break;
            case 'CAD':
                mostrar_CAD();
                break;
        }
    }

    function seleccionarModulo(s) {
        if (modal) {
            return;
        }
        if (s == modulo_activo) {
            mostrarVentana();
            return;
        }
        modulo_activo = s;
        vista_actualizada = false;
        // mostrar_contextual(s);
        mostrarVentana();
    }

    function abrir_plano(sURL, sId) {
        if (!isset(sURL) || !isset(sId)) {
            return;
        }

        plano.url = sURL;
        seleccionarModulo('VIS');   // Se encarga de abrir la imagen dentro del editor
        mostrar_plano(sURL);
        plano.id_plano = sId;

        seleccionarModulo("CAD");    //pasar directamente a editar CAD
    }

    function mostrar_plano(sURL) {
        var sURL = 'url(' + sURL + ')';

        var maxWidth = '';
        var maxHeight = '';

        maxWidth = $('.balloon-view').closest('.container').width()+'px!important';
        maxHeight = $('.balloon-view').closest('.container').width()+'px!important';

        if(maxWidth != ''){
            maxHeight = 'max-height:'+maxHeight+';';
            maxWidth = 'max-width:'+maxWidth+';';
        }

        $(window).on('load', '#d_fondo', function() {
            dimensionar_acetato();
        });

        plano.alto = $('#t_app').attr('data-image-height');
        plano.ancho = $('#t_app').attr('data-image-width');

        $('#t_app').attr(
            'style',
            'margin:0px auto;'+
            'height: ' + plano.alto + 'px!important;' +
            'width:' + plano.ancho + 'px;' +
            maxHeight +
            maxWidth
        );
        
        dFondoWidth = (parseInt(plano.ancho) * 94) / 100;
        dFondoHeight = (parseInt(plano.alto) * 94) / 100;

        $('#d_fondo').css('background-image', sURL);
        $('#d_fondo').css('background-size', 'contain');
        $('#d_fondo').css('background-repeat', 'no-repeat');
        $('#d_fondo').css('background-position', 'center');
        $('#d_fondo').css('height', dFondoHeight + 'px');
        $('#d_fondo').css('width', dFondoWidth + 'px');

        return ({ ancho: plano.ancho, alto: plano.alto });
    }

    function resizeFinalizado() {
        $('.mark-number.selected a').click();
        htmls();
        setTimeout(function () {
            crear_objetos_CAD(json_CAD_desglosado);
            $('.loadingBalloons').detach();
        }, 500);
    }

    return {
        selectPiece: function (piece) {
            $('.cms-block-products').find('div[data-mark="' + piece + '"] a[data-change-image]').click();
        },
        init: function () {
            htmls();

            setTimeout(function () {
                crear_objetos_CAD(json_CAD_desglosado);
            }, 500);

            /*
            var $obj = null;
            var xi_obj = 0;
            var x_min = 0;
            var x_max = 0;
            $(document).on('mousemove', function (e) {
                if ($obj == null) {
                    return;
                }
                //
                if ($obj) {
                    if (e.pageX < x_min) {
                        $obj.offset({ left: x_min });
                        return;
                    }
                    if (e.pageX > x_max) {
                        $obj.offset({ left: x_max });
                        return;
                    }
                    $obj.offset({ left: e.pageX + 2 });
                }
            });
            */

            if(window.originalWidth == undefined && window.originalWidth == null)
                window.originalWidth = $(window).width();

            $(window).resize(function () {
                if (window.originalWidth != $(window).width()) {

                    window.originalWidth = $(window).width();
                    clearTimeout(resizeId);

                    $('.balloon-view').append('<div class="loadingBalloons"></div>');
                    resizeId = setTimeout(function(){
                        resizeFinalizado();
                    }, 1000);

                }
            });
        }
    }

}
