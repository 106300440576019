// TODO: Use another nice file input
// This is a minified copy from yosimitso/workingforumbundle
!function(a) {
    a.fn.nicefileinput = function(t) {
        var e = {
            label: "Browse...",
            fullPath: !1
        };
        return t && a.extend(e, t),
            this.each(function() {
                var t = this;
                if (void 0 === a(t).attr("data-styled")) {
                    var l = Math.round(1e4 * Math.random())
                        , r = new Date
                        , i = r.getTime() + l.toString()
                        , n = a('<input type="text" readonly="readonly">').css({
                        display: "block",
                        "float": "left",
                        margin: 0,
                        padding: "0 5px"
                    }).addClass("NFI-filename NFI" + i)
                        , s = a("<div>").css({
                        overflow: "hidden",
                        position: "relative",
                        display: "block",
                        "float": "left",
                        "white-space": "nowrap",
                        "text-align": "center"
                    }).addClass("NFI-button NFI" + i).attr("disabled", a(t).attr("disabled")).html(e.label);
                    a(t).after(n),
                        a(t).wrap(s),
                        a(".NFI" + i).wrapAll('<div class="NFI-wrapper" id="NFI-wrapper-' + i + '" />'),
                        a(".NFI-wrapper").css({
                            overflow: "auto",
                            display: "inline-block"
                        }),
                        a("#NFI-wrapper-" + i).addClass(a(t).attr("class")),
                        a(t).css({
                            opacity: 0,
                            position: "absolute",
                            border: "none",
                            margin: 0,
                            padding: 0,
                            top: 0,
                            right: 0,
                            cursor: "pointer",
                            height: "60px"
                        }).addClass("NFI-current"),
                        a(t).on("change", function() {
                            var l = a(t).val();
                            if (e.fullPath)
                                n.val(l);
                            else {
                                var r = l.split(/[/\\]/);
                                n.val(r[r.length - 1])
                            }
                        }),
                        a(t).attr("data-styled", !0)
                }
            })
    }
}(jQuery);
