$('.cms-block-text-carousel').each(function(index) {
    var btns = $(this).find(".btn-carousel");

    for (var i = 0; i < btns.length; i++) {
        $(btns[i]).on("click", function() {
            var n = parseInt($(this).attr('data-slide-to'));
            $(this).closest('.cms-block').find('.carousel').carousel(n);
            $(this).closest('.cms-block').find('.carousel').carousel("pause");
            $(this).closest('.cms-block').find('[data-slide-to]').removeClass("active");
            $(this).closest('.cms-block').find('[data-slide-to=' + n + ']').addClass("active");
        });
    }

    $(this).find('.carousel-indicators li').on("click", function() {
        slide = $(this).attr('data-slide-to');
        $(this).closest('.cms-block').find('.carousel-inner a[data-slide-to="' + slide + '"]').click();        
    });

    /////////////////////////////////////////////////////////////////
    /// ON SLIDE ACTION

    var targetNodes         = $(this).find(".carousel-indicators li");
    var MutationObserver    = window.MutationObserver || window.WebKitMutationObserver;
    var myObserver          = new MutationObserver (mutationHandler);
    var obsConfig           = { childList: true, characterData: true, attributes: true, subtree: true };

    //--- Add a target node to the observer. Can only add one node at a time.
    targetNodes.each ( function () {
        myObserver.observe (this, obsConfig);
    } );

    function mutationHandler (mutationsList, observer) {
        for(var mutation of mutationsList) {
            if (mutation.type == 'childList') {
                // console.log('A child node has been added or removed.');
            }
            else if (mutation.type == 'attributes') {
                // console.log('The ' + mutation.attributeName + ' attribute was modified.');
                // console.log('Element',mutation.target);
                let element = mutation.target;
                if($(element).hasClass('active')){
                    let slide = $(element).attr('data-slide-to');
                    $(element).closest('.cms-block').find('.carousel-button').find('[data-slide-to]').removeClass("active");
                    $(element).closest('.cms-block').find('.carousel-button').find('[data-slide-to=' + slide + ']').addClass("active");
                }
            }
        }
    }
});
