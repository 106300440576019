// TODO: Relocate...
if (document.querySelector('.toggle-toggles') != null) {
    document.querySelector('.toggle-toggles').addEventListener('click', function () {
        document.querySelectorAll('.toggle').forEach(function (e) {
            e.classList.toggle('visible');
        });
    });
}

function zoomerViewEvent(gallery){
    jQuery(gallery).find(".slider-view-zoom").css("display", "none");

    jQuery(gallery).find(".slider-view").hover(
        function(){
            jQuery(gallery).find(".slider-view-zoom").css("display", "block");
        },
        function(){
            jQuery(gallery).find(".slider-view-zoom").css("display", "none");
        }
    );

    jQuery(gallery).find(".slider-view-zoom").hover(function(){
      jQuery(gallery).find(".slider-view-zoom").css("display", "block");
    });
}

function refreshSelectors(){
    var selectors = $('.node-data-table');
    var selector = null;
    for (var i = 0; i < selectors.length; i++) {
        selector = selectors.get(i);
        $(selector).DataTable({
            searching: true,
            sDom: 'rt',
            order: [[0, 'asc']],
            iDisplayLength: 1000
        });
        merge_data_table(selector);
        $(selector).on('order.dt', function () {
            merge_data_table(this);
        });
        $(selector).find('th select').each(function () {
            $(this).change(function (e) {
                var table = $(this).closest('.node-table');
                var th = $(this).parent().parent();
                
                var value = $(this).val().trim();

                if (value === '-') {
                    $(table).DataTable().column($(th).index()).search('').draw();
                } else {
                    $(table).DataTable().column($(th).index()).search(value).draw();
                }
                populate_all_options(this);
            });
            populate_options(this);
        });
    }

    selector = $('#node_search_form');
    $(selector).submit(function (e) {
        e.preventDefault();
        var data = $(this).serialize();
        data = data + '&ajax=1';
        $.ajax({
            url: window.location.href,
            type: 'GET',
            dataType: 'json',
            data: data,
            success: function (data) {
                var selectors = $('.node-data-table');
                for (var i = 0; i < selectors.length; i++) {
                    selector = selectors.get(i);
                    var data_table = $(selector).DataTable();
                    var regex = '^(';
                    var words = [];
                    for (var j = 0; j < data.length; j++) {
                        var reference = data[j].reference;
                        regex += reference;
                        words.push(reference);
                        if (j + 1 < data.length) {
                            regex += '|';
                        }
                    }
                    regex += ')$';
                    if (regex === '^()$') {
                        regex = '';
                    }
                    var body = $(data_table.table().body());
                    var column = data_table.column(0);
                    body.unhighlight({'element': 'mark'});
                    var filtered_data_table = column.search(regex, true, false, true);
                    filtered_data_table.draw();
                    if (filtered_data_table.table().page.info().recordsDisplay === 0) {
                        $(selector).closest('.node-table-wrapper').hide();
                        var node = $(selector).closest('.child-node');
                        var hide_node = true;
                        var inner_tables = $(node).find('.node-data-table');
                        for (j = 0; j < data.length; j++) {
                            var inner_table = $(inner_tables).get(j);
                            if ($(inner_table).is(":visible")) {
                                hide_node = false;
                            }
                        }
                        if (hide_node) {
                            $(node).hide();
                        }
                    } else {
                        $(selector).closest('.node-table-wrapper').show();
                        $(selector).closest('.child-node').show();
                        column.search('').draw();
                        body.highlight(words, {'element': 'mark', 'wordsOnly': true});
                    }
                }
            }
        });
    });

    selectors = $('.add_to_cart_form');
    for (i = 0; i < selectors.length; i++) {
        selector = selectors.get(i);
        $(selector).submit(function (e) {
            e.preventDefault();
            var reference = $(this).find('#add_to_cart_form_reference').val();
            var quantity_input = $(this).find('#add_to_cart_form_quantity');
            var quantity = quantity_input.val();
            quantity_input.val(0);
            add_cart_item(reference, quantity);
        });
    }
}

$(document).ready(function () {
    update_cart_counter();
    refreshSelectors();

    /****** INPUT TYPE NUMBER ******/
    $(document).on('click',':input[type="number"]',function() {
        $(this).select();
    });

    $(document).on('change',':input[type="number"]',function() {
        if ($(this).attr('min') != undefined){
            let attrMin = $(this).attr('min');
            if (typeof attrMin !== '') {
                if ($(this).val() < attrMin) $(this).val(attrMin);
            }
        }
    });
    
});

function merge_data_table(selector) {
    $(selector).find('td').each(function () {
        $(this).attr('rowspan', 1);
        $(this).show();
    });
    var seen = {};
    var times = {};
    $(selector).find('td').each(function () {
        var $this = $(this);
        var index = $this.index();
        var txt = $this.html();
        if ($this.hasClass('mergeable-cell') && seen[index] === txt) {
            times[index] += 1;
            var parent = $this.parent();
            for (var i = 1; i < times[index]; i++) {
                parent = parent.prev();
            }
            $(parent.children()[index]).attr('rowspan', times[index]);
            $this.hide();
        }
        else {
            seen[index] = txt;
            times[index] = 1;
        }
    });
    $(selector).find('th').each(function () {
        $(this).off('click');
        $(this).on('click', function (e) {
            if ($(e.target).parents('.no-order').length || $(e.target).hasClass('no-order')) {
                return;
            }
            var data_table = $(this).closest('.node-table').DataTable();
            var order = data_table.order();
            var index = $(this).index();
            if ((order[0][0] === index && order[0][1] === 'desc') || order[0][0] !== index) {
                data_table.order([index, 'asc']);
            }
            else {
                data_table.order([index, 'desc']);
            }
            data_table.draw();
        });
    });
}

function populate_all_options(selector) {
    $(selector).parent().parent().parent().find('select').each(function () {
        populate_options(this);
    });
}

function populate_options(selector) {
    var selected_value = $(selector).val();
    $(selector).empty();
    var data_stack = ['-'];
    var column = $(selector).parent().parent().index();
    
    var table = $(selector).closest('.node-table').DataTable();
    
    var rows = table.rows({filter: 'applied'});
    
    for (var i = 0; i < rows[0].length; i++) {
        var row = table.row(rows[0][i]);
        
        var dataColumn = row.data()[column];
        
        dataColumn = dataColumn.toString();
        if(dataColumn.indexOf('<a') != -1){
            dataColumn = $(dataColumn).text();
        }
        
        var cell_data = dataColumn;
        if ($.inArray(cell_data, data_stack) === -1) {
            data_stack.push(cell_data);
        }
    }

    if (data_stack.length <= 2 && selected_value === '') {
        $(selector).remove();
    } else {
        for (i = 0; i < data_stack.length; i++) {
            var data = data_stack[i];
            var option = new Option(data, data);
            $(selector).append($(option));
            if (selected_value !== '') {
                $(selector).val(selected_value);
            } else {
                $(selector).val('-');
            }
        }
    }
}

function update_cart_counter() {
    $.get(Routing.generate('frontend.products.cart.counter'), function (data) {
        cartCounter = document.getElementById('cart-counter');
        if (cartCounter !== null ) {
            cartCounter.textContent = ' (' + data.counter + ')';
        }
    });
}

function add_cart_item(reference, quantity) {
    var data = {'reference': reference, 'quantity': quantity};
    $.ajax({
        url: Routing.generate('products.cart_add_item'),
        type: 'POST',
        dataType: 'json',
        data: data,
        success: function (data) {
            if (data.status === true) {
                update_cart_counter();
                $('#item_added_to_cart_message').show();
                add_message('add_to_cart_message', 'success', 'The item has been added to the cart.');

            } else {

            }
        }
    });
}

function add_message(message_area_id, message_type, message) {
    var element = document.createElement('div');
    element.setAttribute('class', 'alert alert-' + message_type + ' alert-dismissable');
    var close = document.createElement('a');
    close.setAttribute('href', '#');
    close.setAttribute('class', 'close');
    close.setAttribute('data-dismiss', 'alert');
    close.setAttribute('aria-label', 'close');
    close.appendChild(document.createTextNode('\u00D7'));
    element.appendChild(close);
    var span = document.createElement('span');
    span.textContent = message_type[0].toUpperCase() + message_type.slice(1) + '! ';
    element.appendChild(span);
    element.appendChild(document.createTextNode(message));
    messageArea = document.getElementById(message_area_id);
    if (messageArea !== null)  {
        messageArea.appendChild(element);
    }
}
