$('.cms-block-products-by-category').each(function(index) {
    $(this).find('a[data-id]').on("click", function() {
        $(this).closest('.cms-block').find('.detail-product').addClass('d-none');
        $(this).closest('.cms-block').find('.detail-product-' + $(this).attr('data-id')).removeClass('d-none');
        $(this).closest('.cms-block-products-by-category').find('a.btn[data-id]')
            .removeClass('btn-primary').addClass('btn-secondary');
        $(this).closest('.cms-block-products-by-category').find('a.btn[data-id=' + $(this).attr('data-id') + ']')
            .removeClass('btn-secondary').addClass('btn-primary');
    });
});
var urlParams = new URLSearchParams(window.location.search);
if (urlParams.has('related')) {
    $('.cms-block-products-by-category').find('a[data-id=' + urlParams.get('related') + ']').click();
}

jQuery(document).ready(function() {
    
    jQuery('.cms-block-products-by-category .gallery-slick-v0').slick();

});
