var expanded = '';
function megaMenuResize() {
	var newWidth = $('body').width();
	newWidth = parseInt(newWidth) + 10;

    var element = $('.mega-menu-v2')
        .parent('.nav-item')[0];
    var left = element !== undefined ? element.getBoundingClientRect().left : 0;

	left = parseInt(left) + 5;

	$('.mega-menu-v2').css({ width: newWidth + 'px', left: '-' + left + 'px' });
}

function selectParentLinkMenu(parentElement) {
	parentElement.each((index, element) => {
		// Get parent name of link
		/* var productParentName = $(element)
			.children('.dropdown-item')
			.text();

		productParentName = String(productParentName)
			.trim()
			.toLowerCase();

		// Create slug to compare
		// var slugName = productParentName.split(' ').join('-');
		var slugName = productParentName.slice(0, split.length - 2).join("-");

		slugName = slugName.normalize('NFD').replace(/[\u0300-\u036f]/g, ''); */

		var productParentName = $(element)
			.children('.dropdown-item')
			.attr('href');

		var url = productParentName.split('/');
		var slugName = url[4];

		// Get Main Menu Element to show
		var showMenu = $(element).find('.dropdown-menu');

		// Verify if body has the same class as the name of the link selected
		if ($('body').hasClass(slugName)) {

			$(element).addClass('selected');

			showMenu.show();

			return false;
		}
	});
}

function selectParentLinkSubMenu(parentElement) {
	parentElement.each((index, element) => {

		// var slugName = $(element).attr('data-slug');
		
		var productParentName = $(element).parent('.dropdown-menu').siblings('.dropdown-item').attr('href');
		var url = productParentName.split('/');
		var slugName = url[4];

		// console.log('slugName: ', slugName);
			
		// Get Main Menu Element to show
		var showMenu = $(element).parent('.dropdown-menu');

		// console.log('showMenu: ', showMenu);

		$(element).parent('.dropdown-menu').parent('.dropdown-submenu').addClass('selected');
		showMenu.show();
	});
}

// Update to selected and show children menu items
function showMegaMenuChild(element) {

	/* MEGA MENU 2 */
	if ($('.mega-menu-v2').length > 0) {

		/* MEGA MENU V2 ACTION AND STYLE */
		$('.mega-menu-v2 .children-items > .item > ul').hide();

		if (element == undefined || element == null) {
			$('.mega-menu-v2 .main-nav > .item')
				.first()
				.addClass('selected');
		} else {
			$('.mega-menu-v2 .main-nav > .item').removeClass('selected');
			$(element).addClass('selected');
		}

		var itemSelected = $('.mega-menu-v2 .main-nav > .item.selected');

		var parentName = itemSelected.find('.dropdown-item').text();
		parentName = String(parentName).trim();

		$('#main-nav-title > h3').html(parentName);

		var hrefMainMenu = itemSelected.find('.dropdown-item').attr('href');
		hrefMainMenu = String(hrefMainMenu).trim();

		$('#main-nav-title > .goto-link > a').attr('href', hrefMainMenu);

		$('.mega-menu-v2 .children-items > .item[data-parent-name="' + parentName + '"] > ul').show();

		var dataCollectionName = $('.mega-menu-v2 .children-items > .item[data-parent-name="' + parentName + '"] > ul > li').attr('data-collection-name');

		if (dataCollectionName != undefined) {
			var collectionId = $('.mega-menu-v2 .children-items > .item[data-parent-name="' + parentName + '"] > ul > li').attr('data-collection-id');
			$('#main-nav-title')
				.find('.more-info')
				.html('<span data-collection-id="'+collectionId+'">'+dataCollectionName+'</span>');
		} else {
			$('#main-nav-title')
				.find('.more-info')
				.html('');
		}
	}
}

jQuery(document).ready(function($) {
	showMegaMenuChild();
	if ($('.mega-menu-v2').length > 0) {
		/* CANCEL CLICK ACTION ON MAIN NAV */
		$(document).on('click', '.mega-menu-v2 .main-nav > .item', function(event) {
			event.preventDefault(); // Cancel click default event (href goto)

			showMegaMenuChild($(this));

			event.stopPropagation(); // Cancel click event propagation
		});
	}

	if ($('.left-col-category-menu').length > 0) {
		if ($('.left-col-category-menu').find('.dropdown-submenu').length > 0) {
			selectParentLinkMenu($('.left-col-category-menu > .navbar-nav > .dropdown-submenu'));

			if ($('.left-col-category-menu').find('.dropdown-submenu').find('.dropdown-menu').find('.active').length > 0) {
				var activeSubmenu = $('.left-col-category-menu').find('.dropdown-submenu').find('.dropdown-menu').find('.active');
				
				selectParentLinkSubMenu(activeSubmenu);
			}
		}
	}

	megaMenuResize();
	$(window).resize(function() {
		megaMenuResize();
	});
});
