
product_search_component = {
    methods: {
        addToCartFormSubmit: function (event) {
            function operate_cart_item(action, reference, source, quantity, itemId, form) {
                var data = {'reference': reference, 'source': source, 'quantity': quantity, 'itemId': itemId};
                if (action == "add") {
                    var url = Routing.generate('frontend.products.cart.item.quantity.' + action);
                } else if (action == "remove") {
                    var url = Routing.generate('frontend.products.cart.item.' + action);
                }
                $.ajax({
                    url: url,
                    type: 'POST',
                    dataType: 'json',
                    data: data,
                    success: function (data) {
                        if (data.status === true) {
                            // function .... $.fn.blockCart.updateCartCounter();
                            $.get(Routing.generate('frontend.products.cart.counter'), function (data) {
                                cartCounter = document.getElementById('cart-counter');
                                if (cartCounter !== null) {
                                    cartCounter.textContent = ' (' + data.counter + ')';
                                }
                            });
                            $('#item_added_to_cart_message').show();
                            message = Translator.trans("alert.label_" + action + "_item");
                            // $.growl.notice({ title: Translator.trans("alert.label_alert_success"), message: message });
                            $.alert({
                                title: Translator.trans("alert.label_alert_success"),
                                content: message,
                                // autoClose: 'ok|300',
                                theme: 'modern',
                                type: 'success',
                                buttons: {
                                    ok: {
                                        // isHidden: true,
                                        text: Translator.trans("button.okey"),
                                        btnClass: 'btn-primary'
                                    }
                                }
                            });
                        } else {
                            message = Translator.trans("alert.label_failed_" + action + "_item");
                            //$.growl.error({ title: Translator.trans("alert.label_alert_error"), message: message });
                            $.alert({
                                title: Translator.trans("alert.label_alert_error"),
                                content: message,
                                // autoClose: 'ok|300',
                                theme: 'modern',
                                type: 'red',
                                buttons: {
                                    ok: {
                                        // isHidden: true,
                                        text: Translator.trans("button.okey"),
                                        btnClass: 'btn-danger'
                                    }
                                }
                            });
                        }
                        for (const [key, value] of Object.entries(data)) {
                            if (key.substr(0, 11) == "remove-row-") {
                                $(form).closest('.' + key.substr(7)).remove();
                            }
                        }
                    }
                });
            }

            form = $(event.target).closest('.product').find('form');

            var reference = $(form).find('.reference').val();
            var source = $(form).find('.source').val();
            var quantity_input = $(form).find('.quantity');
            // var itemId = $(form).attr('data-item-id');
            var itemId = $(form).find('.reference').val();
            var quantity = quantity_input.val();
            var action = $(form).attr('data-action');
            if (action == 'add') {
                quantity_input.val(0);
            }
            if (quantity > 0) {
                operate_cart_item(action, reference, source, quantity, itemId, form);
            } else {
                message = Translator.trans("alert.label_quantity_minimum");
                // $.growl.error({ title: Translator.trans("alert.label_alert_error"), message: message });
                $.alert({
                    title: Translator.trans("alert.label_alert_error"),
                    content: message,
                    // autoClose: 'ok|300',
                    theme: 'modern',
                    type: 'red',
                    buttons: {
                        ok: {
                            // isHidden: true,
                            text: Translator.trans("button.okey"),
                            btnClass: 'btn-danger'
                        }
                    }
                });
            }
        },
        itemSelected (item) {
            this.q = item.name;
        },
        itemClicked (item) {
        },
        getLabel (item) {
            if (item) {
                return item.name
            }
            return '';
        },
        update (text) {
            var result = [];
            for(var i in this.categories) {
                if  ((new RegExp(text.toLowerCase())).test(this.categories[i].name.toLowerCase())) {
                    result.push(this.categories[i]);
                }
            }
            this.items = result;
        }
    },
    data: {
        'template': templateProductSearch
    }

};

